import { z } from "zod";

export const networkInvitePayloadSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  email: z.string().optional(),
  phone_number: z.string(),
  hourly_wage: z.string().optional(),
});

export type NetworkInvitePayload = z.infer<typeof networkInvitePayloadSchema>;
