import { format, parse } from "date-fns";

type CommonDateFormats =
  | "MMM yyyy"
  | "MM/dd/yyyy"
  | "MMM d"
  | "MM/dd"
  | "MM/dd/yyyy- h:mm aaa"
  | "MMM dd, yyyy"
  | "MMMM dd, yyyy"
  | "yyyy-MM-dd"
  | "EEE, MMM d h:mm aaa"
  | "EEE, MMM d"
  | "EEEE, MMMM d"
  | "EEE, MMMM d, yyyy"
  | "EEEE"
  | "EEEEE"
  | "EEEEEE"
  | "MM/dd h:mm aaa"
  | TimeFormats;

type TimeFormats = "h:mm aaa";

const timeFormatLookup = {
  backend: "HH:mm:ss",
  default: "h:mm aaa",
} as const;

export function formatDate({
  date,
  format: formatPattern,
}: {
  date?: Date | string | number;
  format: CommonDateFormats;
}): string {
  if (!date) return "";
  const dateDate = typeof date === "string" ? new Date(date) : date;
  return format(dateDate, formatPattern);
}

type TimeFormatOptions = keyof typeof timeFormatLookup;

export const parseTime = (
  time: string,
  formatString: TimeFormatOptions = "default",
  referenceDate: Date = new Date(),
): Date => parse(time, timeFormatLookup[formatString], referenceDate);

export const formatTime = (time: Date, formatString: TimeFormatOptions = "default") =>
  format(time, timeFormatLookup[formatString]);

export const reformatTime = (time: string, format: { in: TimeFormatOptions; out: TimeFormatOptions }) =>
  formatTime(parseTime(time, format.in), format.out);

export const isTwoDayTimeRange = (startTime: string | Date, endTime: string | Date) => {
  const startTimeFormatted = typeof startTime === "string" ? startTime : formatTime(startTime);
  const endTimeFormatted = typeof endTime === "string" ? endTime : formatTime(endTime);
  return startTimeFormatted.endsWith("pm") && endTimeFormatted.endsWith("am");
};
