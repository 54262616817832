import { Auth0Client, type Auth0ClientOptions } from "@auth0/auth0-spa-js";

import { env } from "config/env";
import { track } from "utils/analytics";

const TOKEN_KEY = "token";

const options: Auth0ClientOptions = {
  domain: env.REACT_APP_AUTH0_DOMAIN,
  clientId: env.REACT_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    scope: "openid",
    audience: env.REACT_APP_AUTH0_AUDIENCE,
    redirect_uri: window.location.origin,
  },
};
const auth = new Auth0Client(options);

function getValidToken() {
  const itemStr = localStorage.getItem(TOKEN_KEY);

  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);

  if (Date.now() / 1000 > item.expiry) {
    localStorage.removeItem(TOKEN_KEY);
    return null;
  }
  return item.value;
}

export function setValidToken(options: { token: string; seconds?: number }) {
  const defaultValue = { key: TOKEN_KEY, seconds: 2592000 };
  const { key, token, seconds } = { ...defaultValue, ...options };
  const item = {
    value: token,
    expiry: Date.now() / 1000 + seconds,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export async function checkAuthentication(): Promise<string | undefined> {
  const searchParams = new URLSearchParams(window.location.search);
  const currentToken = getValidToken();
  const callback = searchParams.get("callback");
  const pathname = callback ? decodeURIComponent(callback) : undefined;
  if (!currentToken && pathname) track("User Login", { pathname });
  if (searchParams.has("token")) {
    setValidToken({ token: searchParams.get("token") ?? "" });
    return;
  }

  if (currentToken || env.REACT_APP_ENV === "automation") {
    return pathname;
  }

  try {
    await updateSavedToken();
    return pathname;
  } catch (e: any) {
    if (searchParams.has("code") && searchParams.has("state")) {
      await auth.handleRedirectCallback();
      await updateSavedToken();
      track("User Login", { pathname });
      return pathname;
    }
    if (e.error === "login_required") {
      login();
      return;
    }
    throw e;
  }
}

async function updateSavedToken() {
  const { access_token, expires_in } = await auth.getTokenSilently({ ...options, detailedResponse: true });
  setValidToken({ token: access_token, seconds: expires_in });
  return access_token;
}

export function getTokenSilently() {
  let token = getValidToken();
  if (token) return token;
  token = updateSavedToken();
  return token;
}

async function login() {
  const currentParams = new URLSearchParams(window.location.search);
  const phoneNumber = currentParams.get("phone_number");
  const accountLinkingToken = currentParams.get("account_linking_token");
  currentParams.delete("phone_number");
  currentParams.delete("account_linking_token");

  const utmParams = Object.fromEntries([...currentParams.entries()].filter(([key]) => key.startsWith("utm_")));

  const redirectParams = new URLSearchParams({
    callback: `${window.location.pathname}?${currentParams}`,
    ...utmParams,
  });

  await auth.loginWithRedirect({
    authorizationParams: {
      redirect_uri: `${window.location.origin}?${redirectParams}`,
      ...Object.fromEntries(currentParams.entries()),
      account_linking_token: accountLinkingToken,
      phone_number: phoneNumber,
    },
  });
}

export async function logout(params?: Record<string, string>) {
  localStorage.removeItem(TOKEN_KEY);
  const searchParams = new URLSearchParams(params);
  track("User Logout", {});
  await auth.logout({ logoutParams: { returnTo: `${window.location.origin}?${searchParams}`, params } });
}

export async function startAccountLinking() {
  const token = getTokenSilently();
  await logout({ account_linking_token: token });
}
