import { format, parse } from "date-fns";

type DateFormats = "EEE" | "EEEE, MMM d" | "EEE, MMM d" | "MMM d" | "MM/dd/yyyy";

export function formatDate(date: Date | number, pattern: DateFormats) {
  return format(date, pattern);
}

const timeFormatLookup = {
  backend: "HH:mm:ss",
  default: "h:mm aaa",
} as const;

type TimeFormatOptions = keyof typeof timeFormatLookup;

export const parseTime = (
  time: string,
  formatString: TimeFormatOptions = "default",
  referenceDate: Date = new Date(),
): Date => parse(time, timeFormatLookup[formatString], referenceDate);

export const formatTime = (time: Date, formatString: TimeFormatOptions = "default") =>
  format(time, timeFormatLookup[formatString]);

export const reformatTime = (time: string, format: { in: TimeFormatOptions; out: TimeFormatOptions }) =>
  formatTime(parseTime(time, format.in), format.out);
