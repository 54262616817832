import { zodResolver } from "@hookform/resolvers/zod";
import type { MaskedPattern } from "imask";
import { type UseFormProps, useForm } from "react-hook-form";
import type { ReactMaskOpts } from "react-imask";
import { z } from "zod";

const errorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === "invalid_type" && (issue.received === "undefined" || issue.received === "null")) {
    return { message: "This field is required" };
  }
  return { message: ctx.defaultError };
};
z.setErrorMap(errorMap);

const requiredStringSchema = z.string().trim().min(1, "This field is required");

const phoneNumberRegex =
  /^\(*\+*[1-9]{0,3}\)*-*[1-9]{0,3}[-. /]*\(*[2-9]\d{2}\)*[-. /]*\d{3}[-. /]*\d{4} *e*x*t*\.* *\d{0,4}$/;
const phoneNumberSchema = requiredStringSchema.regex(phoneNumberRegex, "Invalid phone number");
const phoneNumberOrEmptyRegex =
  /^(\(*\+*[1-9]{0,3}\)*-*[1-9]{0,3}[-. /]*\(*[2-9]\d{2}\)*[-. /]*\d{3}[-. /]*\d{4} *e*x*t*\.* *\d{0,4})?$/;
const optionalPhoneNumberSchema = z.string().regex(phoneNumberOrEmptyRegex, "Invalid phone number");

const transformPhoneInput = (input?: string) => {
  if (!input) return "";
  return input.split("+1")[1];
};

const transformPhoneOutput = (output?: string) => {
  if (!output) return "";
  return `+1${output.replace(/\D/g, "")}`;
};

const postalCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
const postalCodeSchema = requiredStringSchema.regex(postalCodeRegex, "Invalid postal code");

const urlRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/;

type UseZodFormProps<TSchema extends z.ZodType<any, any>, TContext> = Pick<
  UseFormProps<TSchema["_input"], TContext>,
  "defaultValues" | "values" | "context"
> & {
  schema: TSchema;
  schemaTransform?: (
    schema: TSchema,
    values: TSchema["_input"],
    context: TContext | undefined,
  ) => Promise<z.ZodType<z.output<typeof schema>>> | z.ZodType<z.output<typeof schema>>;
};
const useZodForm = <TSchema extends z.ZodType<any, any>, TContext = any>({
  schema,
  schemaTransform,
  ...props
}: UseZodFormProps<TSchema, TContext>) =>
  useForm<TSchema["_output"], TContext, TSchema["_output"]>({
    mode: "onChange",
    resolver:
      schemaTransform ?
        async (data, context, options) =>
          zodResolver(await schemaTransform(schema, data, context))(data, context, options)
      : zodResolver(schema),
    delayError: 500,
    ...props,
  });

const makeCurrencyMaskProps = (maskProps: Partial<MaskedPattern> = {}) =>
  ({
    mask: [
      { mask: "" },
      {
        mask: "$AMOUNT",
        blocks: {
          AMOUNT: {
            mask: Number,
            radix: ".",
            mapToRadix: [","],
          },
        },
        lazy: false,
        ...maskProps,
      },
    ],
    unmask: true,
  }) satisfies ReactMaskOpts;

const currencyMaskProps = makeCurrencyMaskProps();

const wageMaskProps = makeCurrencyMaskProps({
  mask: "$WAGE /hr",
  blocks: {
    WAGE: {
      mask: Number,
      scale: 2,
      padFractionalZeros: true,
      normalizeZeros: true,
      radix: ".",
      mapToRadix: [","],
    },
  },
});

const phoneMaskProps = { mask: "(000) 000 0000" } satisfies ReactMaskOpts;
const zipCodeMaskProps = { mask: "00000" } satisfies ReactMaskOpts;
const phoneNumberTransform = {
  input: (value: string | undefined | null) => value?.replace("+1", "") ?? "",
  output: (value: string) => (value ? `+1${value}` : value),
};

export {
  currencyMaskProps,
  optionalPhoneNumberSchema,
  phoneMaskProps,
  phoneNumberRegex,
  phoneNumberSchema,
  phoneNumberTransform,
  postalCodeRegex,
  postalCodeSchema,
  requiredStringSchema,
  transformPhoneInput,
  transformPhoneOutput,
  urlRegex,
  useZodForm,
  wageMaskProps,
  zipCodeMaskProps,
};
