import { type SanitizedPath, route } from "react-router-typesafe-routes/dom";
import { zod } from "react-router-typesafe-routes/zod";
import { z } from "zod";

export const CONNECT_ROUTES = <const T extends string>(basePath: SanitizedPath<T>) =>
  route(
    basePath,
    {
      searchParams: {
        ordering: zod(z.enum(["completion_rate", "rating", "gigs_worked"])).default("gigs_worked"),

        market_id: zod(z.number()),
        pro_uuid: zod(z.string()),

        is_favorite: zod(z.boolean()),
        is_verified: zod(z.boolean()),
        has_worked_together: zod(z.boolean()),

        rating_gte: zod(z.number()),
        time_blocks: zod(z.array(z.number())),
        certifications: zod(z.array(z.number())),
        pro_certifications: zod(z.array(z.string())),
        days_of_week: zod(z.array(z.string())),
        hours_per_week: zod(z.array(z.number())),
        contractor_types: zod(z.array(z.number())),
        previous_positions: zod(z.array(z.number())),
        badge_types: zod(z.array(z.string())),

        pro_fullname: zod(z.string()),

        skill_group: zod(z.string()),
      },
    },
    {
      PROFILE: route(":proId", {
        params: { proId: zod(z.number()).defined() },
        searchParams: { offerType: zod(z.enum(["select", "existing"])) },
      }),
    },
  );
