import { route } from "react-router-typesafe-routes/dom";
import { zod } from "react-router-typesafe-routes/zod";
import { z } from "zod";

import type { ChatTokenAuth } from "api/resources/messaging";
import { Routes } from "routing/Routes";

const groupRoute = "shift/:groupId";
const channelRoute = "chat/:channelUrl";

export const ROUTES = {
  root: "/chats/:chatToken",
  group: groupRoute,
  channel: channelRoute,
  groupedChannel: `${groupRoute}/${channelRoute}`,
};

const CHANNEL_ROUTE = route(channelRoute, { params: { channelUrl: zod(z.string()) } });
const GROUP_ROUTE = route(groupRoute, { params: { groupId: zod(z.string()) } }, { CHANNEL: CHANNEL_ROUTE });

export const STANDALONE_ROUTES = {
  ROOT: route(
    "chats/:chatToken",
    { params: { chatToken: zod(z.string()) } },
    { GROUP: GROUP_ROUTE, CHANNEL: CHANNEL_ROUTE },
  ),
};

export const INBOX_ROUTES = {
  ROOT: route("inbox", {}, { GROUP: GROUP_ROUTE, CHANNEL: CHANNEL_ROUTE }),
};

export type RootRouteParams = { chatToken: string };
export type ChannelRouteParams = { groupId: string; channelUrl: string };

export const getChatLink = (chatToken: string) => ROUTES.root.replace(":chatToken", chatToken);
export const getShiftLink = (chatData: ChatTokenAuth) =>
  `${Routes.INBOX}/${ROUTES.group.replace(":groupId", chatData.shift_encrypted_id)}`;
