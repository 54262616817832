import { z } from "zod";

export const NetworkInviteStatus = z.enum(["ACCEPTED", "SUBMITTED", "EXPIRED"]);
export type NetworkInviteStatus = z.infer<typeof NetworkInviteStatus>;
export const networkInviteSchema = z.object({
  id: z.string(),
  email: z.string(),
  sent_ts: z.string(),
  status: NetworkInviteStatus,
  error_message: z.null(),
  link: z.string(),
  phone_number: z.string(),
  expiration_date: z.string(),
  /** float ex: "25.00" */
  hourly_wage: z.string(),
});
