type BooleanFlagKey =
  | "initech-sms-opt-in"
  | "initech-customer-portal-web"
  | "move-gig-to-the-past"
  | "allow-boosting-gig-manually"
  | "ripple"
  | "message-support-button-visable"
  | "maintenance-mode"
  | "pro-location-modal-view"
  | "temp-show-gig-autoselect-toggle"
  | "gig-options-settings"
  | "request-4-digit-code-to-clock-in"
  | "temp-organizations"
  | "organization-invite"
  | "organization-info"
  | "organization-billing-info"
  | "unapproved-active-locations"
  | "tired-badging-filters"
  | "auto-match-warning-dialog"
  | "automatch-carousel"
  | "engage";

type ValueFlags = {
  /**
   * 0: Schedule V1
   *
   * 1: Schedule V2 Phase 1
   *
   * 2: Schedule V2 Phase 2
   *
   * 3: Schedule V2 Phase 3
   */
  "schedule-v2": 0 | 1 | 2 | 3 | 4;
};
type ValueFlagKey = keyof ValueFlags;

export type FeatureFlagKey = BooleanFlagKey | ValueFlagKey;
export type FeatureFlagValue = Record<BooleanFlagKey, boolean> & ValueFlags;

export const defaultFlags: FeatureFlagValue = {
  "initech-sms-opt-in": false,
  "initech-customer-portal-web": false,
  "move-gig-to-the-past": false,
  "allow-boosting-gig-manually": false,
  ripple: false,
  "message-support-button-visable": false,
  "maintenance-mode": false,
  "pro-location-modal-view": false,
  "temp-show-gig-autoselect-toggle": false,
  "gig-options-settings": false,
  "request-4-digit-code-to-clock-in": false,
  "temp-organizations": false,
  "organization-invite": false,
  "organization-info": false,
  "organization-billing-info": false,
  "unapproved-active-locations": false,
  "tired-badging-filters": false,
  "auto-match-warning-dialog": false,
  "automatch-carousel": false,
  "schedule-v2": 0,
  engage: false,
};
